import { Button, Checkbox } from "antd";
import moment from "moment";
import React from "react";

function UpdateTable({ item, selectTitles, selectedTitles }) {
  return (
    <table>
      <tbody>
        <tr>
          <td>
            {" "}
            <Checkbox
              onChange={(e) => selectTitles(e, item)}
              defaultChecked={false}
              checked={selectedTitles.includes(item.mi_id)}>
              {item.reportName}
            </Checkbox>
          </td>
          <td style={{ width: "30%" }}>{item.last_pushed_by || "N/A"}</td>
          <td style={{ width: "20%" }}>
            {moment(item.last_pushed_on).format("MMMM Do YYYY") || "N/A"}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default UpdateTable;
