import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { APP_REDUCER } from "./Reducer";

const persistConfig = {
  key: "primary",
  storage,
};

//for redux dev tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const pReducer = persistReducer(persistConfig, APP_REDUCER);

let store = createStore(pReducer, composeEnhancers(applyMiddleware(thunk)));
let persistor = persistStore(store);

export default {
  store,
  persistor,
};
